import { useEffect, useState } from "react";
import Greeting from "./components/greeting/Greeting";
import Home from "./components/home/Home";
import { useSelector } from "react-redux";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyBTjNzSEm3J4zHPX7hxjtpTeA2u-sbCXX4",
    authDomain: "pyromatic-portfolio.firebaseapp.com",
    projectId: "pyromatic-portfolio",
    storageBucket: "pyromatic-portfolio.appspot.com",
    messagingSenderId: "220095746733",
    appId: "1:220095746733:web:9489e0bc8264fb25abac24",
    measurementId: "G-LVDKBX8YQT",
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  analytics.app.automaticDataCollectionEnabled = true;

  const greetingPlayed = useSelector((state) => state.greetingPlayed.value);
  const [hideGreeting, setHideGreeting] = useState(greetingPlayed);

  useEffect(() => {
    if (greetingPlayed) {
      setTimeout(() => {
        setHideGreeting(true);
      }, 1600 / 8);
    }
  });

  return (
    <div>
      {!hideGreeting && <Greeting />}
      <Home />
    </div>
  );
}

export default App;
